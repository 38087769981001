import axios from 'axios'

import { AppConfig } from '@/configs/app.config'
import router from '@/router/router'
import { clearToken, getToken } from '@/utilities/token-utilities'
import { useAuthStore } from '@/store/modules/authorization.store'
import { Authorization } from '@/request/requests/authorization.request'

const domain = localStorage.getItem('domain_api')
export const request = axios.create({
  baseURL: domain ? domain : AppConfig.backUrl,
  headers: {
    'Content-Type': 'application/json',
  },
})

request.interceptors.request.use(
  (config) => {
    const accessToken = getToken()

    if (accessToken) {
      config.headers.authorization = `Bearer ${accessToken}`
    }

    return config
  },
  (error) => {
    console.log(error)
  },
)

request.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401 || error.response?.statusText === 'Unauthorized') {
      localStorage.removeItem('token')
      clearToken()
      return error.response
    } else if (error.config?.bubbleError) {
      throw error.response
    } else {
      await errorHandler(error)
      return error.response
    }
  },
)

async function errorHandler(error) {
  if (error.status === 401 || error.response.status === 401) {
    clearToken()
    await router.push({ name: 'Authorization' })
  } else if (
    ((error.response.status === 422 || error.status === 422) && error.data?.message === 'Пользователь в архиве') ||
    error.response.data?.message === 'Пользователь в архиве'
  ) {
    await router.push({ name: 'ErrorIsArchived' })
  } else if (
    error.status === 422 ||
    error.response.status === 422 ||
    error.status === 409 ||
    error.response.status === 409 ||
    error.status === 403 ||
    error.response.status === 403
  ) {
    console.log(error)
  } else {
    await router.push({ name: 'Error', params: { id: error.status } })
  }
}
