import { request } from '@/request/request'

export class Deal {
  static async getCarTypes() {
    return await request.get('/api/dict/car-types')
  }

  static async getCarBrands(params = {}) {
    return await request.get('/api/dict/car-brands', { params })
  }

  static async getCarBrandModel(brandId, params) {
    return await request.get(`/api/dict/car-brands/${brandId}/models`, { params })
  }

  static async getDealTypes() {
    return await request.get('/api/dict/deal-types')
  }

  static async getRejectionReasons() {
    return await request.get('/api/dict/rejection-reasons')
  }

  static async rejectDeal(dealId, params) {
    return await request.post(`/api/deals/${dealId}/reject`, params)
  }

  static async getDeal(dealId, forEdit = 0) {
    const params = {}

    if (forEdit) {
      params['for-edit'] = forEdit
    }
    return await request.get(`/api/deals/${dealId}`, { params })
  }

  static async archiveDeal(dealId) {
    return await request.post(`/api/deals/${dealId}/archive`)
  }

  static async restoreDeal(dealId) {
    return await request.post(`/api/deals/${dealId}/restore`)
  }

  static async resumeDeal(dealId) {
    return await request.patch(`/api/deals/${dealId}/resume`)
  }

  static async terminateDeal(dealId) {
    return await request.patch(`/api/deals/${dealId}/terminate`)
  }

  static async closeDeal(dealId) {
    return await request.post(`/api/deals/${dealId}/close`)
  }

  static async deleteManagerFromDeal(dealId, managerId) {
    return await request.delete(`/api/deal/${dealId}/users/${managerId}`)
  }

  static async addManagerToDeal(dealId, params) {
    return await request.post(`/api/deal/${dealId}/users`, params)
  }

  static async getDetailPackage(dealId, packageId, bubbleError = false) {
    return await request.get(`/api/deals/${dealId}/packages/${packageId}`, { bubbleError })
  }

  static async createNewDeal(params, bubbleError = false) {
    return await request.post('/api/deals', params, { bubbleError })
  }

  static async editDeal(dealId, params, bubbleError = false) {
    return await request.put(`/api/deals/${dealId}`, params, { bubbleError })
  }

  static async stopEdit(dealId) {
    return await request.post(`/api/deals/${dealId}/stop-edit`)
  }

  static async getQuiz(dealId, bubbleError = false) {
    return await request.get(`/api/deals/${dealId}/questionnaire`, { bubbleError })
  }

  static async setQuizAnswers(dealId, params, bubbleError = false) {
    return await request.put(`/api/deals/${dealId}/questionnaire`, params, { bubbleError })
  }

  static async fetchFinishData(id, bubbleError = false) {
    return request.get(`/api/deals/${id}/making-deal`, { bubbleError })
  }

  static async saveFinishData(id, data, bubbleError = false) {
    return request.patch(`/api/deals/${id}/making-deal-update`, data, { bubbleError })
  }

  static async saveAndSendFinishData(id, data, bubbleError = false) {
    return request.patch(`/api/deals/${id}/making-deal-send`, data, { bubbleError })
  }

  static async getCountries({ search }, bubbleError = false) {
    return await request.get('/api/dict/countries', { params: { search }, bubbleError })
  }

  static async checkNewBrand(params, bubbleError = false) {
    return await request.post('/api/dict/car-brands/validate', params, { bubbleError })
  }

  static async checkNewModel(params, bubbleError = false) {
    return await request.post('/api/dict/car-model/validate', params, { bubbleError })
  }

  static async getComments({ dealId, params }, bubbleError = false) {
    return await request.get(`/api/deals/${dealId}/comments`, { params, bubbleError })
  }

  static async addComment({ dealId, body }, bubbleError = false) {
    return await request.post(`/api/deals/${dealId}/comments`, body, { bubbleError })
  }

  static async deleteComment(commentId) {
    return await request.delete(`/api/comments/${commentId}`)
  }

  static async editComment({ commentId, body }, bubbleError = false) {
    return request.patch(`/api/comments/${commentId}`, body, { bubbleError })
  }

  static async switchLogic(dealId, params, bubbleError = false) {
    return await request.put(`/api/deals/${dealId}/switch-logic`, params, { bubbleError })
  }
}
